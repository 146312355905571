import React, { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { Image } from '@/atoms/Image'
import { AmazonPrimeVideoLogo } from '@/atoms/Logos/AmazonPrimeVideoLogo'
import { AngelLogo } from '@/atoms/Logos/AngelLogo'
import { AppleTvLogo } from '@/atoms/Logos/AppleTvLogo'
import { BluRayDiscLogo } from '@/atoms/Logos/BluRayDiscLogo'
import { DVDSimpleLogo } from '@/atoms/Logos/DVDSimpleLogo'
import { FandangoAtHomeLogo } from '@/atoms/Logos/FandangoAtHomeLogo'
import { MicrosoftLogo } from '@/atoms/Logos/MicrosoftLogo'
import { PrimeVideoLogo } from '@/atoms/Logos/PrimeVideoLogo'
import { RedboxLogo } from '@/atoms/Logos/RedboxLogo'
import { VuduLogo } from '@/atoms/Logos/VuduLogo'
import { YouTubeLogo } from '@/atoms/Logos/YouTubeLogo'
import { AsH2, AsH3, TitleSM } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { WhereToWatch } from '@/services/WhereToWatchService'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { ScreenType, useScreenType } from '@/utils/ScreenUtils'
import { useSafeTrack } from '@/utils/analytics'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface Props {
  projectName: string
  projectSlug: string
  whereToWatch: WhereToWatch
}

const UTM_PARAMS = '?utm_campaign=dvd-sales&utm_source=hub-where-to-watch&utm_medium=web'
export const DigitalWhereToWatchSection: FC<Props> = ({ projectName, projectSlug, whereToWatch }) => {
  const { t } = useTranslate('theatrical-presales')
  const router = useRouter()
  const hash = (router.asPath as string).split('#')[1]
  const hashLink = 'where-to-watch'

  const screenType = useScreenType()

  const track = useSafeTrack()

  const trackTVOD = (partner: string) => {
    track('TVOD Add To Cart', {
      eventName: 'TVOD Add To Cart',
      projectSlug,
      partner,
      category: 'where-to-watch',
    })
  }

  const trackDVDBluRay = (category: string) => {
    track('TVOD Add To Cart', {
      eventName: 'TVOD Add To Cart',
      projectSlug,
      partner: 'shop.angel.com',
      category: `where-to-watch-${category}`,
    })
  }

  let streamLogoHeight: number
  let mediaLogoHeight: number

  if (screenType === ScreenType.mobile || screenType === ScreenType.mobileplus) {
    streamLogoHeight = 18
  } else {
    streamLogoHeight = screenType === ScreenType.tablet ? 25 : 34
  }
  if (screenType === ScreenType.mobile || screenType === ScreenType.mobileplus) {
    mediaLogoHeight = 24
  } else {
    mediaLogoHeight = screenType === ScreenType.tablet ? 35 : 50
  }

  useEffect(() => {
    if (hash === hashLink) {
      const destination = document.getElementById(hashLink)
      if (destination) {
        setTimeout(() => destination.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }), 500)
      }
    }
  }, [hash, hashLink])

  return (
    <section
      id={hashLink}
      aria-labelledby="where-to-watch-title"
      className="relative flex w-full flex-col items-center gap-y-4 rounded-2xl px-4 py-6 backdrop-blur-[60px] md:max-w-[380px] lg:h-fit lg:min-w-[520px] lg:max-w-[520px]"
      style={{ background: 'rgba(255, 255, 255, 0.01)' }}
    >
      <div className="gap-y-82 relative flex w-full flex-col items-center">
        <div className="flex flex-col items-center">
          <TitleSM id="where-to-watch-title" as={AsH2} className="w-full text-center" weight="bold" color="white">
            <Translate t={t} i18nKey="whereToWatchTheShowv3" values={{ projectName }}>
              Where to Watch <span className="inline-block">{{ projectName }}</span>
            </Translate>
          </TitleSM>
        </div>
      </div>

      <div className="relative flex w-full flex-col items-center gap-y-8">
        <div className="flex max-w-[350px] flex-col items-center justify-center gap-x-4 gap-y-8 lg:max-w-[500px]">
          <div className="flex w-full flex-wrap items-center justify-center gap-4">
            <a
              href={whereToWatch?.internalLinkOverride ?? `${paths.watch.index}/${projectSlug}`}
              onClick={() => trackTVOD('angel-studios')}
              className="max-h-8 rounded-lg !bg-[#585856] px-4 py-2 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 lg:max-h-14 lg:px-6 lg:py-4"
              style={{ background: 'rgba(255, 255, 255, 0.10)' }}
            >
              <AngelLogo
                className="max-h-4 max-w-[46px] shrink-0 lg:max-h-6 lg:max-w-[70px]"
                height={streamLogoHeight}
              />
            </a>
            <When condition={whereToWatch?.enableStreamOnAmazonPrime}>
              <a
                target="_blank"
                href={whereToWatch?.streamOnAmazonPrimeLink || ''}
                onClick={() => trackTVOD('prime-video')}
                className="max-h-8 rounded-lg !bg-[#585856] px-4 py-2 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 lg:max-h-14 lg:px-6 lg:py-4"
                style={{ background: '!rgba(255, 255, 255, 0.10)' }}
              >
                <PrimeVideoLogo className="max-h-4 shrink-0 lg:max-h-6" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableAmazonPrime}>
              <a
                target="_blank"
                href={whereToWatch?.amazonPrimeLink || ''}
                onClick={() => trackTVOD('amazon-prime')}
                className="max-h-8 rounded-lg !bg-[#585856] px-4 py-2 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 lg:max-h-14 lg:px-6 lg:py-4"
                style={{ background: 'rgba(255, 255, 255, 0.10)' }}
              >
                <AmazonPrimeVideoLogo className="max-h-4 max-w-[82px] shrink-0 lg:max-h-6" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableAppleTv}>
              <a
                target="_blank"
                href={whereToWatch?.appleTvLink || ''}
                onClick={() => trackTVOD('apple-tv')}
                className="max-h-8 rounded-lg !bg-[#585856] px-4 py-2 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 lg:max-h-14 lg:px-6 lg:py-4"
                style={{ background: 'rgba(255, 255, 255, 0.10)' }}
              >
                <AppleTvLogo className="max-h-4 max-w-[50px] shrink-0 lg:max-h-6" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableRedbox}>
              <a
                target="_blank"
                href={whereToWatch?.redboxLink || ''}
                onClick={() => trackTVOD('redbox')}
                className="max-h-8 rounded-lg !bg-[#585856] px-4 py-2 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 lg:max-h-14 lg:px-6 lg:py-4"
                style={{ background: 'rgba(255, 255, 255, 0.10)' }}
              >
                <RedboxLogo className="max-h-4 shrink-0 lg:max-h-6" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableGooglePlay}>
              <a
                target="_blank"
                href={whereToWatch?.googlePlayLink || ''}
                onClick={() => trackTVOD('google-play')}
                className="max-h-8 rounded-lg !bg-[#585856] px-4 py-2 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 lg:max-h-14 lg:px-6 lg:py-4"
                style={{ background: 'rgba(255, 255, 255, 0.10)' }}
              >
                <Image
                  alt="Google Play"
                  src={getCloudinaryImageUrl({
                    height: 24,
                    path: '/v1733341378/angel-studios/misc-icons/google-play-logo-white_4x.webp',
                    width: 119,
                    transforms: 'e_trim',
                  })}
                  className="max-h-4 max-w-[79px] shrink-0 lg:max-h-6 lg:max-w-[119px]"
                  height={streamLogoHeight}
                  width={streamLogoHeight * 3.5}
                  aria-label={t('googlePlay', 'Google Play')}
                />
              </a>
            </When>
            <When condition={whereToWatch?.enableYoutube}>
              <a
                target="_blank"
                href={whereToWatch?.youtubeLink || ''}
                onClick={() => trackTVOD('youtube')}
                className="max-h-8 rounded-lg !bg-[#585856] px-4 py-2 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 lg:max-h-14 lg:px-6 lg:py-4"
                style={{ background: 'rgba(255, 255, 255, 0.10)' }}
              >
                <YouTubeLogo className="max-h-4 max-w-[105px] lg:max-h-6" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableVudu}>
              <a
                target="_blank"
                href={whereToWatch?.vuduLink || ''}
                onClick={() => trackTVOD('vudu')}
                className="max-h-8 rounded-lg !bg-[#585856] px-4 py-2 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 lg:max-h-14 lg:px-6 lg:py-4"
                style={{ background: 'rgba(255, 255, 255, 0.10)' }}
              >
                <VuduLogo className="max-h-4 shrink-0 lg:max-h-6" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableMicrosoft}>
              <a
                target="_blank"
                href={whereToWatch?.microsoftLink || ''}
                onClick={() => trackTVOD('microsoft')}
                className="max-h-8 rounded-lg !bg-[#585856] px-4 py-2 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 lg:max-h-14 lg:px-6 lg:py-4"
                style={{ background: 'rgba(255, 255, 255, 0.10)' }}
              >
                <MicrosoftLogo className="max-h-4 max-w-[120px] shrink-0 lg:max-h-6" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableFandango}>
              <a
                target="_blank"
                href={whereToWatch?.fandangoLink || ''}
                onClick={() => trackTVOD('fandango')}
                className="max-h-8 rounded-lg !bg-[#585856] px-4 py-2 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 lg:max-h-14 lg:px-6 lg:py-4"
                style={{ background: 'rgba(255, 255, 255, 0.10)' }}
              >
                <FandangoAtHomeLogo className="max-h-4 shrink-0 lg:max-h-6" height={streamLogoHeight} />
              </a>
            </When>
          </div>
        </div>
      </div>
      <When condition={whereToWatch?.enableBluray || whereToWatch?.enableDvd}>
        <div className="relative flex w-full flex-col items-center gap-y-4">
          <TitleSM as={AsH3} weight="semibold" className="mt-6 max-w-[262px] text-center" color="white">
            {t('buyNowOnBlurayAndDvd', 'Buy now on Blu-ray and DVD')}
          </TitleSM>
          <div className="flex items-center justify-center gap-x-16">
            <When condition={whereToWatch?.enableBluray}>
              <a
                target="_blank"
                className="flex shrink-0 items-center justify-center rounded-lg !bg-[#585856] px-6 py-4 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 md:min-w-[138px] lg:max-h-16"
                style={{ background: 'rgba(255, 255, 255, 0.10)' }}
                onClick={() => trackDVDBluRay('bluray')}
                href={
                  whereToWatch?.blurayLink
                    ? `${whereToWatch?.blurayLink}${UTM_PARAMS}`
                    : `https://shop.angel.com/collections/dvd-blu-ray${UTM_PARAMS}`
                }
              >
                <BluRayDiscLogo height={mediaLogoHeight} className="lg:max-h-6" />
              </a>
            </When>
            <When condition={whereToWatch?.enableDvd}>
              <a
                target="_blank"
                className="flex shrink-0 items-center justify-center rounded-lg !bg-[#585856] px-6 py-4 backdrop-blur-lg transition-all duration-300 ease-in-out hover:scale-110 md:min-w-[138px] lg:max-h-16"
                style={{ background: 'rgba(255, 255, 255, 0.10)' }}
                onClick={() => trackDVDBluRay('dvd')}
                href={
                  whereToWatch?.dvdLink
                    ? `${whereToWatch?.dvdLink}${UTM_PARAMS}`
                    : `https://shop.angel.com/collections/dvd-blu-ray${UTM_PARAMS}`
                }
              >
                <DVDSimpleLogo height={mediaLogoHeight} className="lg:max-h-6" />
              </a>
            </When>
          </div>
        </div>
      </When>
    </section>
  )
}
