import React from 'react'
import { useTranslate } from '@/utils/translate/translate-client'
import { getHexColor, scaleDimensions } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const DVDSimpleLogo: React.FC<OneColorLogoProps> = ({ height = 24, color = 'white', className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 39, 24)
  const hex = getHexColor(color)
  const { t } = useTranslate('common')

  return (
    <svg
      className={className}
      width={scaledWidth}
      height={scaledHeight}
      viewBox="0 0 56 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={t('dvd', 'DVD')}
    >
      <path
        d="M5.18363 0.000400869L4.47684 2.97341L9.7462 2.97542H10.9827C14.3699 2.97542 16.439 4.33758 15.8639 6.75691C15.2381 9.39123 12.2775 10.5384 9.13189 10.5384H7.94968L9.48428 4.07535H4.21543L1.97327 13.5136H9.4461C15.0653 13.5136 20.4073 10.5475 21.3475 6.75691C21.5199 6.06104 21.4959 4.32249 21.0714 3.28665C21.0604 3.2461 21.0494 3.21452 21.0103 3.13209C20.9952 3.09845 20.9815 2.9445 21.0694 2.91153C21.1182 2.89298 21.2088 2.98852 21.2205 3.01668C21.2645 3.13208 21.2981 3.21792 21.2981 3.21792L26.0529 16.6438L38.1584 2.98042L43.2863 2.97579H44.5228C47.9106 2.97579 49.9966 4.33795 49.4218 6.75728C48.796 9.3916 45.8182 10.5388 42.6728 10.5388H41.49L43.0252 4.07469H37.7564L35.5136 13.5135H42.9874C48.6056 13.5135 53.9857 10.5563 54.8883 6.75677C55.7909 2.95724 51.8432 5.1519e-05 46.1976 5.1519e-05H35.0489C35.0489 5.1519e-05 32.0959 3.49716 31.5477 4.15727C28.6049 7.70111 28.0835 8.65107 27.9949 8.8963C28.0073 8.64901 27.9015 7.69013 26.6464 4.10714C26.3126 3.15301 25.2369 0 25.2369 0H5.18317L5.18363 0.000400869ZM26.243 16.7008C12.0217 16.7008 0.49231 18.335 0.49231 20.3504C0.49231 22.3658 12.0217 24 26.243 24C40.4643 24 51.9938 22.3658 51.9938 20.3504C51.9938 18.335 40.4643 16.7008 26.243 16.7008ZM25.3109 19.206C28.5586 19.206 31.1907 19.7507 31.1907 20.4225C31.1907 21.0943 28.5584 21.639 25.3109 21.639C22.0638 21.639 19.431 21.0943 19.431 20.4225C19.431 19.7507 22.0639 19.206 25.3109 19.206Z"
        fill={hex}
      />
    </svg>
  )
}
