import React, { useRef, useState, TouchEvent as ReactTouchEvent } from 'react'
import classNames from 'classnames'
import { AccountCircleIcon } from '@/atoms/Icons/AccountCircleIcon'
import { UpArrowCircleIcon } from '@/atoms/Icons/UpArrowCircleIcon'
import { Image } from '@/atoms/Image'
import { ParagraphMD, ParagraphSM, TitleMD } from '@/atoms/Text'
import { CatalogCast, formatCastHeadshotPath, isSeriesCast, isTitleCastEmpty } from '@/services/ContentCatalog'
import { CatalogMovie, CatalogSeries } from '@/types/generated-content-catalog-types'
import { useTranslate } from '@/utils/translate/translate-client'

interface CastRailProps {
  catalogTitle?: CatalogMovie | CatalogSeries
}

export function CastRail(props: CastRailProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)
  const minSwipeDistance = 50
  const { t } = useTranslate('theatrical-presales')

  if (isTitleCastEmpty(props.catalogTitle)) return null

  const onTouchStart = (event: ReactTouchEvent) => {
    setTouchEnd(0)
    setTouchStart(event.targetTouches[0].clientX)
  }

  const onTouchMove = (event: ReactTouchEvent) => setTouchEnd(event.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    isLeftSwipe ? handleRightDirection() : handleLeftDirection()
  }

  const handleLeftDirection = () => {
    const scrollLeft = ref?.current?.scrollLeft ?? 0
    let scrollTo

    if (scrollLeft - 240 <= 0) {
      scrollTo = 0
    } else {
      scrollTo = scrollLeft - 240
    }

    ref?.current?.scrollTo({ top: 0, left: scrollTo, behavior: 'smooth' })
  }

  const handleRightDirection = () => {
    const scrollLeft = ref?.current?.scrollLeft ?? 0
    let scrollTo

    if (scrollLeft + 240 <= 0) {
      scrollTo = 0
    } else {
      scrollTo = scrollLeft + 240
    }

    ref?.current?.scrollTo({ top: 0, left: scrollTo, behavior: 'smooth' })
  }

  return (
    <div>
      <div className="flex items-center justify-between pb-2">
        <TitleMD color="white" weight="semibold" className="uppercase">
          {t('filmCast', 'Cast')}
        </TitleMD>
        <div
          className={classNames('flex items-center gap-3', {
            'md:hidden': (props.catalogTitle?.cast.length || 0) <= 3,
          })}
        >
          <div onClick={handleLeftDirection}>
            <UpArrowCircleIcon
              className="-rotate-90 cursor-pointer !stroke-white transition-all duration-300 ease-in-out hover:!stroke-white lg:!stroke-core-gray-300"
              color="inherit"
              size={32}
            />
          </div>
          <div onClick={handleRightDirection}>
            <UpArrowCircleIcon
              className="rotate-90 cursor-pointer !stroke-white transition-all duration-300 ease-in-out hover:!stroke-white lg:!stroke-core-gray-300"
              color="inherit"
              size={32}
            />
          </div>
        </div>
      </div>
      <div
        className="flex items-start gap-4 overflow-hidden"
        ref={ref}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {props.catalogTitle?.cast.map((cast, i) => {
          // NOTE: the shape is the same, but there are current multiple, separate crew types; TODO: after consolidated, removing this type cast
          const c = cast as CatalogCast
          const headshotUrl = formatCastHeadshotPath(c)
          return (
            <div key={i} className="flex w-36 shrink-0 flex-col items-center justify-center">
              <div className="relative mx-auto h-[120px] w-[120px] overflow-hidden rounded-full">
                {headshotUrl ? (
                  <Image alt={cast.name} src={headshotUrl} height={120} width={120} />
                ) : (
                  <div className="bg-core-gray-400">
                    <AccountCircleIcon aria-label="generic-profile-picture" size={120} />
                  </div>
                )}
              </div>
              <div className="w-full text-center">
                <ParagraphMD
                  color="white"
                  weight="bold"
                  className="overflow-hidden text-ellipsis whitespace-nowrap"
                  title={c.name}
                >
                  {c.name}
                </ParagraphMD>
                <ParagraphSM color="core-gray-300" className="whitespace-nowrap">
                  {isSeriesCast(c) ? c.characters[0] : c.character}
                </ParagraphSM>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
